@import './assets/admin/scss/style.scss';

.logo_text{
    color:white;
}

.logo-2 a, .logo a {
    text-decoration: none;
}

.icon-btn:hover {
    cursor: pointer;
}

.form-group option {
    background-color: black;
}

.lnk_gallery{
    color: var(--main-color);
    text-decoration: none;
}

.lnk_gallery:hover {
    color: var(--black-color);
}

.image-view{
    max-height: 200px;
}